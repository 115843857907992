import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import actionHelper from "utils/actionHelper";
import api from "modules/insurance-result/insurance-result.api";
import { RootState } from "../../applications/desktop/store";
import { IInsuranceResultState } from "./data/insurance-result.types";
import { poll } from "common/polling";
import INSURANCE_SEARCH_MODULE from "modules/insurance-search/insurance-search.name";
import ROOT_MODULE from "modules/root/root.name"
import { ApiBaseSataPackageResource } from "common/types/types";

const namespaced = true;

const RUN_INSURANCE_SEARCH = actionHelper("RUN_INSURANCE_SEARCH");

const state: IInsuranceResultState = {
  errorRunInsuranceSearch: null,
  isPending: {
    getSearchResults: false,
    runInsuranceSearch: false,
  },
  searchResults: []
}

const getters: GetterTree<IInsuranceResultState, RootState> = {
  isPending: (state: IInsuranceResultState) => state.isPending,
  searchResults: (state: IInsuranceResultState) => state.searchResults,
}

const actions: ActionTree<IInsuranceResultState, RootState> = {
  async getSearchResults(
    { commit },
    { requestId, uuid }: { requestId: string, uuid: string }
  ): Promise<ApiBaseSataPackageResource> {
    return (await api.getSearchResults(requestId, uuid)).data
  },

  async runSearchResultsPolling(
    { dispatch },
    { requestId, uuid }: { requestId: string, uuid: string }
  ): Promise<ApiBaseSataPackageResource> {

    async function getSearchResults(): Promise<ApiBaseSataPackageResource> {
      return dispatch(
        "getSearchResults",
        { requestId, uuid }
      );
    }

    function validation(result: ApiBaseSataPackageResource): boolean {
      // console.log("runSearchResultsPolling, validation result:", result);
      return !result.page.query.pendingConnectors.length
    }

    return poll(getSearchResults, validation, 20, 1000);
  },

  async runInsuranceSearch({ commit, dispatch, rootGetters }) {
    commit(RUN_INSURANCE_SEARCH.INITIAL)
    try {
      const pollingRequests = rootGetters[`${INSURANCE_SEARCH_MODULE}/searchRequestIds`].map((requestId: string) => {
        return dispatch(
          "runSearchResultsPolling",
          { requestId, uuid: rootGetters[`${ROOT_MODULE}/uuid`] }
        );
      })

      const results = await Promise.all(pollingRequests)
      commit(RUN_INSURANCE_SEARCH.SUCCEEDED, results)
    }
    catch (error) {
      commit(RUN_INSURANCE_SEARCH.FAILED, error)
    }
  }
}

const mutations: MutationTree<IInsuranceResultState> = {
  [RUN_INSURANCE_SEARCH.INITIAL](state) {
    state.isPending.runInsuranceSearch = true
    state.searchResults = []
  },
  [RUN_INSURANCE_SEARCH.SUCCEEDED](state, result) {
    state.isPending.runInsuranceSearch = false
    state.searchResults = result
  },
  [RUN_INSURANCE_SEARCH.FAILED](state, error) {
    state.isPending.runInsuranceSearch = false
    state.errorRunInsuranceSearch = error
  },
}

export const insuranceResultStore: Module<IInsuranceResultState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
