import Vue from "vue";
import Router, { RouteConfig } from "vue-router";

import StartPage from "./startPage/StartPage.vue";

const BuildingSearch = () => import("./flight-app/components/building-search/building-search.template.vue");
const TermsOfUse = () => import("./flight-app/components/building-search/ui/terms-of-use.vue");
const OrderProduct = () => import("./flight-app/components/order-product/order-product.template.vue");
const SuccessPayment = () => import("./payments/success-payment.vue");
const ErrorPayment = () => import("./payments/error-payment.vue");
const CancelPayment = () => import("./payments/cancel-payment.vue");
const ResultSearch = () => import("./flight-app/components/result-search/result-search.template.vue");
const BuildingPackageSearch = () => import("./package-app/components/building-search/building-search.template.vue");
const PackageOrderProduct = () => import("./package-app/components/order-product/order-product.template.vue");
const ResultPackageSearch = () => import("./package-app/components/result-search/result-search.template.vue");

const BuildingHotelSearch = () => import("./hotel-app/components/building-search/building-search.template.vue");
const ResultHotelSearch = () => import("./hotel-app/components/result-search/result-search.template.vue");
const HotelOrderProduct = () => import("./hotel-app/components/order-product/order-product.template.vue");

const BuildingInsuranceSearch = () => import("./insurance-app/components/building-search/building-search.template.vue");
Vue.use(Router);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    // redirect: "/flight"
    component: StartPage
  },
  {
    path: "/1",
    name: "home1",
    // redirect: "/flight"
    component: StartPage
  },
  {
    component: BuildingSearch,
    name: "flight-home",
    path: "/flight"
  },
  {
    component: BuildingPackageSearch,
    name: "package-home",
    path: "/package"
  },
  {
    component: ResultPackageSearch,
    name: "package-result",
    path: "/package-result/:requestId"
  },
  {
    component: ResultSearch,
    name: "result",
    path: "/result/:requestId"
  },
  {
    component: OrderProduct,
    name: "order",
    path: "/order/:orderId"
  },
  // temp: to child component
  {
    component: SuccessPayment,
    name: "successPayment",
    path: "/success-payment/:orderId"
  },
  {
    component: ErrorPayment,
    name: "errorPayment",
    path: "/error-payment/:orderId"
  },
  {
    component: CancelPayment,
    name: "cancelPayment",
    path: "/cancel-payment/:orderId"
  },
  {
    component: PackageOrderProduct,
    name: "package-order",
    path: "/package-order/:orderId"
  },
  {
    component: TermsOfUse,
    name: "terms-of-use",
    path: "/terms-of-use"
  },
  {
    component: BuildingHotelSearch,
    name: "hotel-home",
    path: "/hotel"
  },
  {
    component: ResultHotelSearch,
    name: "hotel-result",
    path: "/hotel-result/:requestId"
  },
  {
    component: HotelOrderProduct,
    name: "hotel-order",
    path: "/hotel-order/:orderId"
  },
  {
    component: BuildingInsuranceSearch,
    name: "insurance-home",
    path: "/insurance"
  },
];
export default new Router({ routes });
