import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import actionHelper from "utils/actionHelper";
import api from "modules/insurance-search/insurance-search.api";
import { RootState } from "../../applications/desktop/store";
import { IInsuranceQuery, IInsuranceSearchState } from "./data/insurance-search.types";
import { IInsurancePax, ISearchSimpleFormInputs } from "applications/desktop/insurance-app/components/building-search/validate/building-search.simple-form-fields.validate";
import ROOT_MODULE from "modules/root/root.name";
import PRODUCT_SEARCH from "modules/product-search/product-search.name";
import { QueryTypes } from "modules/product-search/data/product-search.consts";

const namespaced = true;

const BEGIN_SEARCH = actionHelper("BEGIN_SEARCH");
const BEGIN_SEARCH_INSURANCE = actionHelper("BEGIN_SEARCH_INSURANCE");
const GET_REGIONS = actionHelper("GET_REGIONS");
const GET_SERVICES = actionHelper("GET_SERVICES");

const state: IInsuranceSearchState = {
  isPending: {
    beginSearch: false,
    beginSearchInsurance: false,
    getRegions: false,
    getServices: false,
  },
  beginSearchResults: [],
  regions: [],
  services: [],
}

const getters: GetterTree<IInsuranceSearchState, RootState> = {
  isPending: (state: IInsuranceSearchState) => state.isPending,
  regions: (state: IInsuranceSearchState) => state.regions,
  services: (state: IInsuranceSearchState) => state.services,
  beginSearchResults: (state: IInsuranceSearchState) => state.beginSearchResults,
  searchRequestIds: (state: IInsuranceSearchState): string[] => state.beginSearchResults.map(beginSearch => beginSearch.requestId),
}

const actions: ActionTree<IInsuranceSearchState, RootState> = {
  async beginInsuranceSearch(
    { commit, dispatch, rootGetters },
    { params }: { params: ISearchSimpleFormInputs }
  ) {
    commit(BEGIN_SEARCH_INSURANCE.INITIAL)

    try {
      const searchRequests = params.paxes.map((pax: IInsurancePax) => {
        const query = {
          age: pax.age,
          arrivalDate: params.dateRange.end,
          departureDate: params.dateRange.start,
          region: params.region[0],
          services: pax.services,
          // websiteType: rootGetters[`${ROOT_MODULE}/isTerminalSearch`] ? "AGENCY_TERMINAL" : "AGENCY_SHOWCASE",
          websiteType: "BACKOFFICE_SHOWCASE", // DEBUG !
        }

        dispatch(`${PRODUCT_SEARCH}/saveQuery`, 
          { query, queryType: QueryTypes.insurance },
          { root: true }
        );

        return dispatch('beginSearch', {
          uuid: rootGetters[`${ROOT_MODULE}/uuid`],
          query: query
        })
      })
      await Promise.all(searchRequests)
      commit(BEGIN_SEARCH_INSURANCE.SUCCEEDED)
    }
    catch {
      commit(BEGIN_SEARCH_INSURANCE.FAILED)
    }
  },

  async beginSearch({ commit }, {uuid, query}: {uuid: string, query: IInsuranceQuery} ) {
    commit(BEGIN_SEARCH.INITIAL)

    try {
      const result = await api.beginSearch(query, uuid)
      commit(BEGIN_SEARCH.SUCCEEDED, result)
      return result
    }
    catch(error) {
      commit(BEGIN_SEARCH.FAILED)
    }
  },

  async getRegions({ commit }, { uuid }: {uuid: string} ) {
    try {
      commit(GET_REGIONS.INITIAL)
      commit(
        GET_REGIONS.SUCCEEDED,
        await api.getRegions(uuid)
      )
    }
    catch(error) {
      commit(GET_REGIONS.FAILED, error)
    }
  },

  async getServices({ commit }, { uuid }: {uuid: string} ) {
    try {
      commit(GET_SERVICES.INITIAL)
      commit(
        GET_SERVICES.SUCCEEDED,
        await api.getServices(uuid)
      )
    }
    catch(error) {
      commit(GET_SERVICES.FAILED, error)
    }
  }
}

const mutations: MutationTree<IInsuranceSearchState> = {
  [BEGIN_SEARCH_INSURANCE.INITIAL](state) {
    state.isPending.beginSearchInsurance = true
    state.beginSearchResults = []
  },
  [BEGIN_SEARCH_INSURANCE.SUCCEEDED](state) {
    state.isPending.beginSearchInsurance = false
  },
  [BEGIN_SEARCH_INSURANCE.FAILED](state, error) {
    state.isPending.beginSearchInsurance = false
  },

  [BEGIN_SEARCH.INITIAL](state) {
    state.isPending.beginSearch = true
  },
  [BEGIN_SEARCH.SUCCEEDED](state, response) {
    state.isPending.beginSearch = false
    state.beginSearchResults.push(response.data)
  },
  [BEGIN_SEARCH.FAILED](state, error) {
    state.isPending.beginSearch = false
  },

  [GET_REGIONS.INITIAL](state) {
    state.isPending.getRegions = true
    state.regions = []
  },
  [GET_REGIONS.SUCCEEDED](state, response) {
    state.regions = response.data
    state.isPending.getRegions = false
  },
  [GET_REGIONS.FAILED](state, error) {
    state.isPending.getRegions = false
  },

  [GET_SERVICES.INITIAL](state) {
    state.isPending.getServices = true
    state.services = []
  },
  [GET_SERVICES.SUCCEEDED](state, response) {
    state.services = response.data
    state.isPending.getServices = false
  },
  [GET_SERVICES.FAILED](state, error) {
    state.isPending.getServices = false
  },
}

export const insuranceSearchStore: Module<IInsuranceSearchState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
}
